import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 500.000000 500.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">
    <path d="M2235 3826 c-457 -99 -812 -416 -960 -859 -51 -153 -68 -274 -62
-442 8 -207 43 -348 133 -530 55 -113 175 -288 182 -267 2 7 -21 45 -52 85
-61 80 -152 256 -185 357 -73 228 -81 504 -20 727 89 326 315 614 613 780 296
166 671 204 996 101 401 -128 715 -445 835 -847 39 -131 50 -217 49 -391 -1
-151 -3 -175 -32 -282 -43 -165 -120 -324 -226 -466 -21 -29 -36 -55 -32 -59
7 -7 15 2 85 102 136 195 231 480 231 698 0 174 -41 383 -106 535 -136 321
-400 576 -726 702 -145 56 -234 72 -428 76 -166 4 -196 2 -295 -20z"/>
<path d="M2439 3325 c-160 -44 -279 -168 -279 -292 0 -28 -4 -33 -22 -33 -59
1 -154 64 -200 134 -16 22 -28 34 -28 26 0 -51 101 -137 196 -167 36 -11 56
-24 63 -40 15 -33 15 -33 -23 -33 -46 0 -145 -32 -200 -64 -77 -45 -145 -152
-146 -229 0 -83 48 -144 136 -172 49 -15 54 -19 54 -47 0 -56 33 -159 47 -145
2 2 -4 23 -12 47 -8 23 -15 62 -15 85 l0 42 63 6 c123 12 227 69 267 148 26
50 26 109 -1 143 -18 22 -28 26 -75 26 -67 0 -114 -23 -170 -82 -47 -49 -78
-104 -95 -172 -13 -51 -16 -53 -65 -30 -64 30 -86 121 -48 199 42 89 167 190
266 217 44 11 49 10 70 -9 33 -30 63 -43 97 -43 69 0 37 51 -46 71 -24 7 -48
20 -54 30 -5 11 -8 19 -7 19 2 0 50 2 108 4 131 4 203 20 293 65 94 47 137
101 137 172 0 39 -5 55 -27 79 -48 53 -180 74 -284 45z m227 -21 c44 -21 64
-50 64 -92 0 -123 -203 -232 -433 -232 l-90 0 6 45 c14 99 142 241 255 281 52
19 157 18 198 -2z m-339 -425 c7 -6 13 -16 13 -21 0 -16 -53 1 -75 24 l-20 20
34 -6 c19 -3 40 -11 48 -17z m-7 -149 c24 -24 25 -34 1 -85 -38 -84 -165 -174
-270 -190 l-44 -7 5 38 c22 162 227 325 308 244z"/>
<path d="M2771 2959 c-224 -44 -401 -205 -401 -366 0 -44 5 -58 30 -87 39 -44
87 -60 154 -52 174 20 336 158 376 318 16 61 6 87 -10 28 -6 -25 -21 -61 -32
-80 -51 -85 -183 -195 -280 -232 -99 -37 -191 -12 -203 56 -28 148 183 360
400 401 134 26 287 -12 351 -88 l26 -31 -38 -31 c-65 -51 -167 -161 -274 -294
-106 -132 -257 -291 -317 -332 l-41 -29 -78 20 c-92 24 -142 25 -189 5 -42
-17 -54 -41 -31 -63 13 -14 36 -17 114 -17 53 1 121 7 150 14 45 11 64 11 140
-4 154 -31 282 -4 418 87 196 131 285 392 203 595 -12 29 -11 33 12 53 36 31
97 62 164 82 l60 18 -55 -5 c-37 -3 -82 -18 -135 -44 l-79 -39 -35 30 c-92 81
-254 116 -400 87z m450 -235 c23 -103 1 -254 -52 -354 -32 -60 -118 -152 -179
-194 -93 -62 -261 -92 -369 -66 l-52 12 73 43 c134 79 217 167 402 430 89 126
146 189 159 176 5 -5 13 -26 18 -47z m-757 -591 c45 -25 -190 -46 -236 -22
-21 11 -21 12 -2 26 15 11 42 14 119 11 55 -3 109 -9 119 -15z"/>
<path d="M1585 1640 l-49 -50 39 -38 c33 -30 34 -31 10 -4 l-29 32 22 22 c21
22 21 22 44 2 l23 -19 -23 26 c-22 25 -22 26 -4 43 18 16 52 13 52 -4 0 -4 8
-11 18 -14 9 -4 3 7 -15 24 -18 16 -33 30 -35 30 -2 0 -26 -23 -53 -50z"/>
<path d="M3313 1651 c-13 -11 -23 -23 -23 -28 1 -4 21 -30 45 -56 44 -48 44
-48 70 -31 33 21 48 49 40 74 -9 28 -61 27 -85 -2 -15 -19 -20 -20 -34 -9 -20
17 -20 35 0 52 13 10 19 10 36 0 21 -13 34 -9 23 8 -10 17 -48 12 -72 -8z
m115 -59 c2 -14 -4 -29 -15 -37 -25 -20 -36 -18 -51 6 -11 18 -11 24 4 40 24
26 58 21 62 -9z"/>
<path d="M1720 1525 c-45 -61 -45 -67 12 -99 27 -16 30 -17 20 -3 -8 9 -22 19
-33 22 -26 9 -24 18 17 75 20 28 34 53 31 56 -3 3 -24 -20 -47 -51z"/>
<path d="M3246 1530 c15 -22 29 -38 32 -35 5 5 -45 75 -54 75 -2 0 8 -18 22
-40z"/>
<path d="M3130 1513 c0 -5 16 -29 35 -55 30 -41 40 -48 68 -47 l32 1 -29 7
c-20 4 -39 21 -60 53 -29 43 -46 59 -46 41z"/>
<path d="M1860 1430 c-33 -65 -38 -86 -14 -55 27 35 65 115 54 115 -5 0 -23
-27 -40 -60z"/>
<path d="M3281 1464 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M3038 1463 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M1978 1448 c27 -14 27 -23 1 -82 -11 -26 -19 -52 -17 -58 2 -6 16 17
31 51 26 59 29 63 55 57 15 -3 3 6 -27 19 -29 14 -56 25 -60 24 -3 0 4 -5 17
-11z"/>
<path d="M3000 1445 c-24 -28 -8 -50 40 -53 49 -4 74 -18 66 -39 -3 -7 -1 -13
5 -13 6 0 9 12 7 28 -3 25 -7 27 -53 30 -54 4 -69 16 -54 44 12 22 6 24 -11 3z"/>
<path d="M2125 1387 c-11 -33 -35 -122 -35 -129 0 -4 7 -8 16 -8 8 0 13 4 10
9 -4 5 0 33 8 63 l14 53 38 -2 c38 -2 36 0 -19 17 -20 7 -29 6 -32 -3z"/>
<path d="M2856 1378 c-3 -7 -7 -42 -9 -78 -3 -36 -3 -56 -1 -44 7 31 72 49 96
27 37 -34 26 -5 -18 48 -51 60 -62 67 -68 47z m54 -65 c0 -7 -4 -13 -9 -13 -5
0 -16 -3 -25 -6 -13 -5 -16 2 -16 37 l0 42 25 -23 c14 -13 25 -29 25 -37z"/>
<path d="M2285 1313 c-3 -27 -9 -60 -11 -75 -5 -24 -2 -28 18 -28 17 0 19 3 9
9 -11 7 -12 21 -4 70 5 34 6 64 2 67 -4 2 -11 -17 -14 -43z"/>
<path d="M2665 1350 c-30 -8 -30 -8 -4 -9 29 -1 39 -16 40 -64 0 -18 5 -41 10
-52 6 -13 7 3 4 52 -5 63 -4 72 12 76 12 3 10 5 -7 5 -14 0 -38 -4 -55 -8z"/>
<path d="M2347 1334 c17 -9 33 -25 36 -36 7 -26 -7 -64 -30 -77 -16 -9 -16
-10 -1 -11 22 0 48 38 48 70 0 31 -37 70 -66 70 -14 0 -10 -5 13 -16z"/>
<path d="M2502 1288 l-20 -53 42 -3 c22 -2 45 -10 49 -18 4 -8 12 -14 17 -14
4 0 -6 32 -23 70 -17 39 -34 70 -38 70 -4 0 -16 -24 -27 -52z m58 -40 c0 -5
-13 -8 -30 -8 -34 0 -34 1 -18 47 l12 34 18 -33 c9 -18 17 -36 18 -40z"/>
<path d="M2148 1313 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M3048 1313 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2145 1240 c11 -5 27 -9 35 -9 9 0 8 4 -5 9 -11 5 -27 9 -35 9 -9 0
-8 -4 5 -9z"/>
<path d="M2471 1204 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
